import React, { useState } from "react";
import { useQuery } from "react-query";
import SectionRange from './SectionRange';
import { v4 } from "uuid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { convertTo12Hour } from '../../../../utils/dateTimeFormatter';
import EmptyData from '../../../../components/EmptyData';
import Loader from '../../../../components/loader/Loader';

function convertDate(isoDate) {
  if (!isoDate) return null;
  let timestamp = Date.parse(isoDate);
  let jsDate = new Date(timestamp);
  let fDate = new Intl.DateTimeFormat("en-uk", {
    dateStyle: "long",
  });
  return fDate.format(jsDate);
}

function capitalizeText(str) {
  if (!str) return null;

  const newText = str.charAt(0).toUpperCase() + str.slice(1);
  return newText;
}

let resultIDS = [
  { id: 1, title: "Questions answered", color: "black" },
  { id: 2, title: "Correct answers", color: "customGreen" },
  { id: 3, title: "Incorrect answers", color: "customOrange" },
  { id: 4, title: "Unanswered questions", color: "slate-400" },
];

export const statusColor = { provisional: "blue-500", fail: "red-500" , pass: "customGreen" }

let percentageValues = ["0%","10","20","30","40","50","60","70","80","90","100%",];

const Result = ({results, isLoading, index}) => {

  const [value, setValue] = React.useState('one');
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const [currentResultId, setCurrentResultId] = useState("");
    const attempts = ['First', 'Second', 'Third', 'Fourth']
  
    if(isLoading) return <Loader />
  
    const UserDetails = () => {
      return (
        <div className="my-4 w-full rounded-md border-b pb-3 border-slate-300">
          <h1 className="text-slate-600 text-xl">{results?.user?.name}</h1>
          <div
            className="mt-3 w-full"
          >
            <div  className="grid grid-cols-2 justify-between">
              <p className="text-baseFont mb-5">
                <b className="font-medium text-lg text-slate-500 pr-3">Started At:</b>{" "}
                {convertDate(results?.final_result?.started_at)} 
                <span className="ml-4">
                   {convertTo12Hour(results?.final_result?.started_at?.slice(
                     11, results?.final_result?.started_at?.length
                   ))}
                </span>
              </p>
              <p className="text-baseFont mb-5">
                <b className="font-medium text-lg text-slate-500 pr-3">Completed At:</b>{" "}
                {convertDate(results?.final_result?.completed_at)} 
                <span className="ml-4">
                   {convertTo12Hour(results?.final_result?.completed_at?.slice(
                     11, results?.final_result?.completed_at?.length
                   ))}
                </span>
              </p>
              <h1 className="text-slate-600 mt-0">
                <b className="font-medium text-slate-500">Result Status:</b>{" "}
                <span
                className={`text-${
                  results?.final_result?.status && statusColor[results?.final_result?.status]}`
                  }
                >
                  {capitalizeText(results?.final_result?.status)}
                </span>
              </h1>
              <h1 className="text-slate-600 mt-0">
                <b className="font-medium text-slate-500">Attempt:</b>{" "}
                <span
                >
                  {capitalizeText(attempts[index])}
                </span>
              </h1>
            </div>
          </div>
       
        </div>
      );
    };

    const aptitude = results?.result_by_category?.find( 
      category => category?.category?.title?.toLowerCase()?.includes('aptitude') || category?.category?.title?.toLowerCase() === 'aptitude'
    )
  
    const personality = results?.result_by_category?.find( 
      category => category?.category?.title?.toLowerCase()?.includes('personality') || category?.category?.title?.toLowerCase() === 'personality'
    )
  
    const integrity = results?.result_by_category?.find( 
      category => category?.category?.title?.toLowerCase()?.includes('integrity') || category?.category?.title?.toLowerCase() === 'integrity'
    )
  return (
    <>
      <UserDetails />
      <div className="my-4 w-full rounded-md mt-12">  
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center justify-start gap-4">
            {results?.results &&
              results?.results?.map((result) => (
                <button
                  className={`${
                    result?.id === currentResultId
                      ? "text-slate-700 text-baseFont font-semibold duration-200"
                      : "text-slate-400 text-baseFont"
                  }`}
                  onClick={() => handleResultId(result)}
                >
                  {result?.category?.title} Test
                </button>
              ))}
          </div>
        </div>
        <section className="-mt-12">
        <Box className="p-0 m-0" sx={{
          '& .MuiTabs-flexContainer .Mui-selected': {
            color: '#8EAF55',  
            borderBottom: '2px solid #8EAF55',  
            outline: 'none',
          },
          '& .MuiTabs-indicator': {
            display: 'none',  
            border: 'none'
          },
            '& .MuiSlider-thumb': {
              boxShadow: 'none', // Remove the default box shadow
              border: 'none', // Remove the default border
            },
        }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor={undefined}
            indicatorColor={undefined}
            className="text-customGreen"
            aria-label="secondary tabs example"
          >
              { aptitude &&
                <Tab value="one" className="pb-1 capitalize text-lg font-semibold" label="Aptitude" />
              }
              { personality &&
                <Tab value="two" className="pb-1 capitalize text-lg font-semibold" label="Personality" />
              }
              { integrity &&              
                <Tab value="three" className="pb-1 capitalize text-lg font-semibold" label="Intergrity" />
             }
          </Tabs>
        </Box>
        <div>
          <div className="p-5 pt-8">
          {
           aptitude && value === 'one' ?
            results?.result_by_category?.length ?  
               aptitude.details?.map( section => {
                 return (
                  <div key={v4()} className="md:flex items-center -mt-2">
                     <span className="block md:-mt-8 opacity-80 w-full md:w-[180px] text-md whitespace-nowrap">
                        {section?.section?.title}
                     </span>
                     {/* <span className={`-mt-8 mr-12 block py-1 w-[78px] text-md ${
                      section?.percentage < 50 ?  ' text-red-500' : 'text-customGreen'
                     } whitespace-nowrap`}>
                        {
                          section?.percentage < 50 ?
                          'failed' : 'passed'
                        }
                     </span> */}
                     <SectionRange 
                      value={section?.percentage} 
                      colorScheme={section?.percentage < 50 ? "#ef4444" : '#8EAF55' }
                     />
                  </div>
                 )
              } )
            : <EmptyData text='No result data found'  /> : null
          }
          </div>
          <div className="p-5 pt-0 -mt-8">
          {
            value === 'two' && personality ?
            results?.result_by_category?.length ?  
              personality?.details?.map( section => {
                 return (
                  <div key={v4()} className="flex items-center -mt-2">
                     <span className="block -mt-8 opacity-80 w-[200px] text-md whitespace-nowrap">
                        {section?.section?.title}
                     </span>
                     <span className={`-mt-8 mr-12 block py-1 w-[78px] text-md ${
                      section?.status !== 'yes' ?  ' text-red-500' : 'text-customGreen'
                     } whitespace-nowrap`}>
                        {
                          section?.status 
                        }
                     </span>
                     <SectionRange
                        value={ section?.percentage} 
                        colorScheme={section?.percentage < 50 ? "#ef4444" : '#8EAF55'}
                     />
                  </div>
                 )
              } )  
            : <EmptyData text='No result data found'  /> : null
          }
          </div>
          <div className="p-5 pt-0 -mt-8">
          {
            value === 'three' && integrity ?
            results?.result_by_category?.length ?  
              integrity?.details?.map( section => {
                 const failed =  Math.ceil((100/(section?.minimum_score + section?.maximum_score)) * section?.percentage) < 50
                 return (
                  <div key={v4()} className="flex items-center -mt-2">
                     <span className="block -mt-8 opacity-80 w-[160px] text-md whitespace-normal">
                        {section?.section?.title} 
                     </span>
                     <span className={`-mt-8 mr-12 block py-1 w-[78px] text-md ${
                      (section?.minimum_score && section?.maximum_score) ? failed
                       ?  ' text-red-500' : 'text-customGreen' : section?.percentage < 50 ?
                       ' text-red-500' : 'text-customGreen'
                     } whitespace-nowrap`}>
                        {
                          (section?.minimum_score && section?.maximum_score) ?
                           failed ? 'failed' : 'passed'
                          :
                          section?.percentage < 50 ? 'Failed' : 'Passed'   
                        }
                     </span>
                     {
                       (section?.minimum_score && section?.maximum_score) ?
                       <div className="w-[calc(100%-238px)] relative">
                          <span 
                            style={{
                              left: `calc(${section?.percentage}%-10px)`
                            }}
                            className="absolute bi bi-check text-2xl pb-1 text-white block z-[100] top-[6px]  border border-customGreen rounded-full h-[20px] w-[20px] bg-customGreen" />
                          <SectionRange 
                              value={
                                [section?.minimum_score, section?.maximum_score]
                              }
                          />
                        </div>
                       :
                       <div className="w-[calc(100%-238px)]">
                            <SectionRange 
                              value={section?.percentage}
                              colorScheme={section?.percentage < 50 ? "#ef4444" : '#8EAF55'}
                          />
                        </div>
                     }
                  </div>
                 )
              } )
            : <EmptyData text='No result data found'  /> : null
          }
          </div>
        </div>
        </section>
      </div>
    </>
  )
}

export default Result