import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarItem from "../SidebarItem/SidebarItem";
import classes from "./Sidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../images/TestAssessify.png";
import toast from "react-hot-toast";
import { faArrowRight, faTimeline } from "@fortawesome/free-solid-svg-icons";
import { api } from "./../../api/index";

const Sidebar = ({
  pages,
  setPage,
  currentPage,
  isOpen,
  setIsOpen,
  windowWidth,
  refreshTime,
}) => {
  const handleItemSelect = (id) => {
    setPage(id);
    if (windowWidth < 768) {
      setIsOpen(false);
    }
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();

  const logOut = async () => {
    const userData = sessionStorage.getItem("user");
    const token = sessionStorage.getItem("userToken");
    try {
      if (userData && token) {
        const response = await api.logout();
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("noapply");
        toast.success(response.message);
      }
      if(sessionStorage.getItem("loginType") === 'join'){
        return navigate( `/joint-exam/${window.sessionStorage.getItem("active-assessment-id")}` )
      }
       navigate("/");
      sessionStorage.removeItem('profile-campaign-id')
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        navigate("/");
      }
    }
  };

  return (
    <div
      className={`${classes.Sidebar} ${
        isOpen ? classes.OpenSidebar : classes.CloseSidebar
      } absolute md:sticky flex-1 flex justify-between flex-col z-10 py-3 overflow-y-auto`}
      style={{
        height: windowWidth < 768 ? "calc(100vh - 43px)" : "100vh",
        borderRight: "1px solid #ccc",
        boxShadow: "0 0 5px 0 #ccc",
      }}
      id="sidebar"
    >
      <div>
        <div className="mb-3 p-5">
          {windowWidth > 768 && (
            <img
              src={Logo}
              alt="test assessify"
              style={{ marginBottom: "30px" }}
            />
          )}
           { sessionStorage.getItem('loginType') === 'join' ?
             parseInt(sessionStorage.getItem('attempt_count')) > 1 
              ?
             <Link
               to={'/candidate/exam-history'}
               //onClick={clicked}
               className={`${classes.SidebarItem} bg-gray-100  py-3 px-2 flex items-center`}
               data-active={location.pathname.includes('/candidates/exam-history') ? true : false}
               title={'Result History'}
             >
               <FontAwesomeIcon icon={faTimeline} /> 
               <p className="text-baseFont ml-3 ">Result History</p>
             </Link> : null
           : null }
          {/* <h3> Dashboard </h3> */}
          {/* {user?.account_type !== "guest" && (
            <>
              <ul>
                <SidebarItem
                  key={"Notifications"}
                  label={"Notifications"}
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  path={"/candidate/notifications"}
                  clicked={() => handleItemSelect(1)}
                  count={10}
                  active={
                    location.pathname.includes("/notifications") ? true : false
                  }
                />
              </ul>
            </>
          )} */}

          <ul>
            {pages[user?.account_type || user?.designation_slug]?.map(
              (page) => (
                <SidebarItem
                  key={page.title}
                  label={page.title}
                  icon={page.icon}
                  path={page.link}
                  clicked={() => handleItemSelect(page.id)}
                  active={location.pathname.includes(page.link) ? true : false}
                  title={page.info}
                />
              )
            )}
          </ul>
        </div>
      </div>
      {windowWidth > 768 && (
        <div className="flex items-center w-full flex-col gap-2">
          <div
            className="flex items-center justify-between py-1 rounded-md px-2"
            style={{ width: "90%" }}
          >
            <span
              className="rounded-full bi bi-person-circle text-gray-600 text-[50px] flex items-center justify-center"
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid #ccc",
              }}
            >
              {/* <img
                src=""
                alt="img"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "block",
                }}
              /> */}
            </span>
            <button
              className="flex items-center justify-center gap-2"
              onClick={() => {
                navigate("/candidate/profile");
              }}
              data-testid="username-btn"
            >
              {user ? `${user?.user?.name || user?.name}`  : null}
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-customOrange text-xl"
              />
            </button>
          </div>
          <button
            id="logout-btn"
            className="py-1 rounded-md text-customOrange duration-200 hover:bg-customOrange hover:text-white"
            style={{
              width: "90%",
              margin: "0 auto",
              border: "1px solid #f68158",
            }}
            onClick={logOut}
          >
            Log out
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
