export const exitFullScreen = () => {
    const  isFullScreen = () => {
        return (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        );
      }
     if( isFullScreen() ){
        if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
     }
  }
 
  export const tranformToUniqueData = (originalArray) => {
      // Use a Set to keep track of unique question_id values
       const uniqueQuestionIds = new Set();
        // Filter the original array to get unique items based on question_id
        const uniqueArray = originalArray.filter(item => {
        // Check if the question_id is not in the Set
        if (!uniqueQuestionIds.has(item.question_id)) {
            // Add the question_id to the Set
            uniqueQuestionIds.add(item.question_id);
            // Return true to include this item in the filtered array
            return true;
        }
            // Return false to exclude this item from the filtered array
             return false;
        });
      return uniqueArray
  }

  export const toPlainAnwers = (questions) => {
     let answers = []
     questions?.forEach( question => {
      question?.answers?.forEach( answer => {
         answers.push({
             question_id: question?.id,
             answer_id: answer?.id,
             answer_text: answer?.text,
             status:  answer?.status
         })
      })
   })
   return answers.filter( ans => ans?.status === true )
  }
  export const getBatchQuestions = (questions, batchAnswers) => {
    const questionIds = batchAnswers?.map(item => item?.question_id);
    return questions?.filter(item => questionIds.includes(item.id));
  }
  export const markBatchQuestions = (questions, batchAnswers) => {
      let markedQuestions = []
      questions?.forEach( question => {
        const correctAnswers = question?.answers?.filter( answer => {
          return answer?.is_correct === true
        })
       const selectedAnswers = batchAnswers?.filter( answer => {
          return answer?.question_id === question?.id
       })
        if(
          question?.type?.includes("multiple correct") || question?.type?.includes("select") ||
          question?.type?.includes("true or false") || question?.type?.includes("yes or no")
        ){
          const areEqual = (correctAnswers?.length === selectedAnswers?.length) &&
            correctAnswers.every((value, index) => value?.id === selectedAnswers[index]?.answer_id);
            markedQuestions.push({ id: question?.id, status: Boolean(areEqual) })
        }
        else if(question?.type?.includes("fill in the") || question?.type?.includes("matching")){
          const areEqual = (correctAnswers?.length === selectedAnswers?.length) &&
            correctAnswers.every((value, index) => value?.text?.toLowerCase() === selectedAnswers[index]?.answer_text?.toLowerCase());
            markedQuestions.push({ id: question?.id, status: Boolean(areEqual) })
        } 
      }) 
    return markedQuestions;
  }