import React, { useState, useEffect } from "react";
import { api } from "../../../api";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Card from "./components/Card";
import { AssessementContext } from "../assessments/AssessmentContext";
import { defaultState } from "../assessments/components/defaultState";
import { exams as examsApi } from "../../../api/exams";

const Dashboard = ({ width }) => {

  const [assessment, setAssessment] = useState(defaultState);
  const [showRedirect, hideRedirect] = useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'))

  const { isLoading: examsLoading, data: numberOfExams } = useQuery(
    "user exams",
    async () => {
      const data = await examsApi.getAssesmentsv2({
        type: null, id: user?.user?.user_id, currentPage:1 , perPage: 1
      });
      return data;
    },  
    {
      select: (data) => data?.data?.meta?.total,
      refetchOnWindowFocus: false,
    }
  );  

  const { data: mockAssessment, isLoading: mockExamLoading } = useQuery(
    "user-group-practice-assessment",
    async () => {
      const data = await api.getPracticeExams({id: user?.user?.user_id, perPage: 1, currentPage: 1});
      return data;
    },
    {
      select: (response) => response?.data?.upcoming_exams?.meta?.total,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: summaryData, isLoading: summaryLoading } = useQuery(
    ["Summary Data"],
    async () => {
      const data = await api.getDashboardSummary(user?.user?.user_id);
      return data;
    },
    {
      select: (response) => response?.data,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: examResult, isLoading: examResultLoading } = useQuery(
    ["get exam history"],
    async () => {
      const data = await api.getExamsHistory(user?.user?.user_id);
      return data.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    hideRedirect(true);
  }, []);

  return (
    <React.Fragment>
      <AssessementContext.Provider value={{ assessment, setAssessment }}>
        {(
          <>
            {/*( showRedirect && mockAssessment?.length) ? 
               <Redirect handleRedirect={hideRedirect} /> : null 
           */}
            <div
              className="rounded-md mt-1 bg-white p-4 gap-4"
              style={{
                display: "flex",
                width: "100%",
                // gridTemplateColumns: width > 768 ? "4fr 1.5fr" : "1fr",
                border: "1px solid #ccc",
              }}
            >
              <div className="w-full pt-4 md:pt-0">
                {/*WELCOME SECTION*/}
                <div className="md:flex items-center justify-between w-full">
                  <div>
                    <h1
                      className="text-darkBlue"
                      style={{ fontSize: "25px", marginTop: "10px" }}
                    >
                      Welcome, {user?.user?.name || user?.name}!
                    </h1>
                  </div>
                </div>
                <h5 className="pl-2 mt-8 text-xl"> Application Overview </h5>
                <section>
                  <div className="mt-5 ml-3">
                    <div className="pb-0 md:flex flex-wrap items-center">
                      <span className="text-xl">Stages</span>
                      <div className="flex items-center md:ml-3 mt-3 md:mt-0">
                        <button className="w-[20px] rounded-lg h-[20px] bg-[#F35B24]"></button>
                        <span className="bi bi-arrow-right text-xl mx-3"></span>
                        <span className="text-md text-[#F35B24]">
                          {" "}
                          Application Stage{" "}
                        </span>
                      </div>
                      <div className="flex items-center md:ml-5 mt-3 md:mt-0">
                        <button className="w-[20px] rounded-lg h-[20px] bg-[#FDB919]"></button>
                        <span className="bi bi-arrow-right text-xl mx-3"></span>
                        <span className="text-md text-[#FDB919]">
                          {" "}
                          Online Test Stage{" "}
                        </span>
                      </div>
                      <div className="flex items-center md:ml-5 mt-3 md:mt-0">
                        <button className="w-[20px] rounded-lg h-[20px] bg-[#87AA4B]"></button>
                        <span className="bi bi-arrow-right text-xl mx-3"></span>
                        <span className="text-md text-[#87AA4B]">
                          {" "}
                          Assessment Day Stage{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="bg-gray-100 p-8 pr-3 mx-3 mb-12 mt-8 justify-between grid grid-cols-1 md:grid-cols-3">
                  <Card
                    title={"Online Test Stage"}
                    text={summaryData?.online_test_total}
                    color="text-[#F35B24]"
                    isLoading={summaryLoading}
                    starts={[
                      {
                        title: "Completed",
                        color: "text-customGreen",
                        value: summaryData?.online_test_completed_total,
                      },
                      {
                        title: "Pending",
                        color: "text-customOrange",
                        value: summaryData?.online_test_pending_total,
                      },
                    ]}
                  />
                  <Card
                    title={"Assessment Day Stage"}
                    text={summaryData?.physical_test_total}
                    color="text-[#FDB919]"
                    starts={[
                      {
                        title: "Completed",
                        color: "text-customGreen",
                        value: summaryData?.physical_test_completed_total,
                      },
                      {
                        title: "Pending",
                        color: "text-customOrange",
                        value: summaryData?.physical_test_pending_total,
                      },
                    ]}
                  />
                  <Card
                    title={"Result"}
                    text={examResult?.meta?.total}
                    isLoading={examResultLoading}
                    color="text-[#87AA4B]"
                    starts={[
                      {
                        title: "Completed",
                        color: "text-customGreen",
                        value: examResult?.summary?.completed || '-',
                      },
                      {
                        title: "Pending",
                        color: "text-customOrange",
                        value: examResult?.summary?.notStarted || '-',
                      },
                    ]}
                  />
                </div>
                <section className="md:mx-auto mb-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8">
                    {/* <div className="w-full mb-5 text-center shadow md:mb-0 py-5 border border-gray-200 rounded-lg bg-white">
                      <p className="text-lg text-center mb-3">
                        My Practice Exams
                      </p>
                      <h1
                        className={`text-5xl text-center font-bold mb-3 mt-2`}
                      >
                        {!mockExamLoading ? mockAssessment : "..."}
                      </h1>
                      <Link
                        to={"/candidate/assessments/view-practice-exams"}
                        className="py-3 bg-[#F35B24] text-white w-[80%] mx-auto text-center rounded-lg text-md px-12"
                      >
                        Click to view
                      </Link>
                    </div> */}
                    <div className="w-full mb-5 shadow md:mb-0 py-5 border border-gray-200 rounded-lg bg-white">
                      <p className="text-lg text-center mb-3">Upcoming Exams</p>
                      <h1
                        className={`text-5xl text-center font-bold mb-3 mt-2`}
                      >
                        {!summaryLoading ? summaryData?.total_upcoming_exams : "..."}
                      </h1>
                      <Link
                        to={"/candidate/assessments/view-assessments"}
                        className="py-3 bg-customGreen text-white w-[80%] mx-auto text-center rounded-lg text-md px-12"
                      >
                        Click to view
                      </Link>
                    </div>
                    <div className="w-full mb-5 shadow md:mb-0 py-5 border border-gray-200 rounded-lg bg-white">
                      <p className="text-lg text-center mb-3">Applications</p>
                      <h1
                        className={`text-5xl text-center font-bold mb-3 mt-2`}
                      >
                        {!summaryLoading ? summaryData?.total_applications : "..."}
                      </h1>
                      <Link
                        to={"/candidate/application/"}
                        className="py-3 bg-darkBlue text-white w-[80%] mx-auto text-center rounded-lg text-md px-12"
                      >
                        Click to view
                      </Link>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )}
      </AssessementContext.Provider>
    </React.Fragment>
  );
};

export default Dashboard;
