import React, { useState, useRef } from "react";
import { api } from "../../../../../../api";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { convertTo12Hour } from "../../../../../../utils/dateTimeFormatter";
import { exams } from "../../../../../../api/exams";

function BookexamModal({ closeModal, refetch }) {

  const [openTime, setOpenTime] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const [selectedTime, setSelectedTime] = useState("Select time");
  const [selectedState, setSelectedState] = useState("Select state");
  const [selectedDate, setSelectedDate] = useState("Select date");
  const [selectedDateId, setSelectedDateId] = useState(null);
  const [selectOutletId, setSelectOutletId] = useState(null);
  const [selectTimetId, setSelectTimeId] = useState(null);
  const [availableDates, setAvailableDates] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])

  const timeRef = useRef(null);
  const stateRef = useRef(null);
  const dateRef = useRef(null);

  const closeOpenMenus = (e) => {
    if (timeRef.current && openTime && !timeRef.current.contains(e.target)) {
      setOpenTime(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const group_id = sessionStorage.getItem("group_id");
  const user_group_id = sessionStorage.getItem("user_group_id");
  const user = JSON.parse(sessionStorage.getItem('user'));
  const examId = JSON.parse(sessionStorage.getItem('active-exam'))?.exam_id

  const { isLoading, data, isError, error } = useQuery(["id"], async () => {
    const data_ = await exams.getBookingsv2(user?.user?.user_id || window.sessionStorage.getItem("user_id"), examId);
    return data_;
  }, { select: res => res});

  const [currentDate, setCurrentDate] = useState({});

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    setOpenTime(false);
  };

  const handleBookExam = async (e) => {
    e.preventDefault();

    if(!selectedDateId){
       toast.error('Please select date')
    }
    else if (selectedTime === "Select time" || selectedTime === ""){
      toast.error('Please select time')
    }
    else {
      let payload = {
        outlet_id: selectOutletId || null,
        date_id: selectedDateId,
        preferred_time_slot: selectedTime,
        user_exam_id: JSON.parse(sessionStorage.getItem('active-exam'))?.user_exam_id,
      };
  
      try {
        toast.loading("loading...");
        const res = await exams.createBookingv2({ 
          payload, userId: user?.user?.user_id || window.sessionStorage.getItem("user_id"), 
           examId
        })
        toast.remove();
        // getAssessmentMutation.mutate()
        toast.success(res.message);
        refetch();
        closeModal(false);
      } catch (error) {
        toast.remove();
        toast.error(error?.response?.data?.error);
        refetch();
      }
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 p-10 ">
      <div className="">
        <div className="min-h-[12rem] w-[94%] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-6 md:p-16 relative">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Book Exam  
          </h3>
          <form onSubmit={handleBookExam}>
          {
              ( 
                data?.data?.length
              ) && (
              <div className="mb-4">
                <label htmlFor="state" className="mb-4 text-lightGrey text-xs">
                  State
                </label>
                <div
                  className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                  
                >
                  <div
                    className="flex justify-between items-center py-4 px-4 "
                    onClick={() => setOpenState(!openState)}
                  >
                    {selectedState}
                  </div>
                  {openState && (
                    <div>
                      <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-40">
                          <div>
                            { data?.data?.length &&
                              data?.data?.map(
                                (state, index) => (
                                  <div
                                    onClick={() => {
                                      setSelectedState(
                                        `${state?.outlet?.name} (${state?.outlet?.outletState?.name})`
                                      );
                                      setSelectOutletId(state?.outlet?.id);
                                      setOpenState(false);
                                      setSelectedDate("Select date");
                                      setSelectedDateId(null);
                                      setCurrentDate(null);
                                      setAvailableDates(state?.examOutletBookingDates)

                                    }}
                                    className="py-2 hover:bg-gray px-4"
                                    key={index}
                                  >
                                    <div> {`${state?.outlet?.name} (${state?.outlet?.outletState?.name})`} </div>
                                  </div>
                                )
                              )}
                          </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="mb-4">
              <label htmlFor="date" className="mb-4 text-lightGrey text-xs">
                Date
              </label>
              <div
                className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                
              >
                <div
                  className="flex justify-between items-center py-4 px-4 "
                  onClick={() => setOpenDate(!openDate)}
                >
                  { selectedDateId ? `${ new Date(selectedDate).toLocaleDateString('en-ng')}` : selectedDate}
                </div>
                {openDate && (
                  <div>
                    <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-40 ">
                      {availableDates?.length === 0 ? (
                        <p className="text-center text-red-500">
                          Select State First
                        </p>
                      ) : (
                        <div>
                          {availableDates?.map((date, index) => (
                            <div
                              onClick={() => {
                                setSelectedDate(date.booking_date);
                                setSelectedDateId(date.id);
                                setCurrentDate(date);
                                setOpenDate(false);
                                setSelectTimeId(null)
                                setSelectedTime("Select time")
                                setAvailableTimes(
                                  JSON.parse((date?.preferred_time_slot))?.length ?
                                   JSON.parse(date?.preferred_time_slot) :
                                  []
                                )
                              }}
                              className="py-2 hover:bg-gray px-4"
                              key={index}
                            >
                              <div> {`${ new Date(date.booking_date).toLocaleDateString('en-ng')}`} </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="time" className="mb-6 text-lightGrey text-xs">
                Time
              </label>
              <div
                className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                
              >
                <div
                  className="flex justify-between items-center py-4 px-4 "
                  onClick={() => setOpenTime(!openTime)}
                >
                  { selectedTime !== 'Select time' ? convertTo12Hour(selectedTime) : 'Select time'}
                </div>
                {openTime && (
                  <div>
                    <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-30">
                      { !selectedDateId ? (
                        <p className="text-center text-red-500">
                          select date first
                        </p>
                      ) : (
                        <div>
                          { availableTimes?.length ?
                            availableTimes?.map(
                              (time, index) => (
                                <div
                                  onClick={() => handleTimeSelection(time)}
                                  className="py-2 hover:bg-gray px-4"
                                  key={index}
                                >
                                  <div>{convertTo12Hour(time)} </div>
                                </div>
                              )
                            )
                          :
                          <div
                            className="py-2 hover:bg-gray px-4"
                          >
                            <div> no time available </div>
                          </div>
                          }
                        </div>
                      )}
                    </div>
                    
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-darkBlue text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Book
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BookexamModal;
