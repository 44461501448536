export const defaultState = {
    activeQuestion : {},
    assessements : {},
    activeAssessment:[],
    isTimedOut: false,
    viewAsessments:{},
    viewExams:{},
    isCompleted: false,
    isTerminated: false,
    savedAnswers:[],
    duration: 0,
    unsupportedActionCount: 0,
    startAssessment: false,
    questionType : 'select',
    questionNumber : 0,
    inEditMode:false,
    isAutomove: false,
    isModified: false,
}