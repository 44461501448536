import React, { useState } from "react";
import Table from "./components/Table";
import { useQuery } from "react-query";
import { exams } from "../../../api/exams";
import Input from "../../../components/input/Input";
import Loader from "../../../components/loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";


const ExamHistory = () => {

  const [examHistory, setExamHistory] = React.useState([]);  
  const [perPage, setPerPage] = useState(8); 
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('')
  const user = JSON.parse(sessionStorage.getItem('user'))
  const { data: examData, isLoading, isError } = useQuery(
    ["get exam history", search, currentPage, perPage ],
    async () => {
      const data = await exams.getExamsHistoryv2({
        id: user?.user?.user_id || sessionStorage.getItem('user_id'), 
        perPage, currentPage, search
      });
      return data.data;
    },
    {
      onSuccess: data => {
        setExamHistory(data?.exams?.data);        
      }
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true },
  );

  return (
    <React.Fragment> 
       <header className="bg-darkBlue max-[500px]:mt-5 md:flex justify-between items-center py-3 px-4 rounded-md mb-4">
            <h1 className="text-white max-[500px]:mb-3 text-lg">
              Exam History &nbsp;
                ({examData?.meta?.total})
            </h1>
            <div className="w-[300px]">
                <Input 
                  type='search'
                  placeholder="Search exam history"
                  onChange={ event => setSearch(event.target.value)}
                />
            </div>
      </header>
      <section>
          { isLoading ? <Loader /> :
            <Table examHistory={examData?.exams} search={search} />
          }
          <Pagination
              data={examData?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
          />
      </section>
    </React.Fragment>
  );
};

export default ExamHistory;
