import React, { useState, useRef } from "react";
import { convertTo12Hour } from "../../../../../../utils/dateTimeFormatter";

function ViewBookingModal({ closeModal, exam}) {
  
  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 p-3 md:p-10 ">
      <div className="">
        <div className="min-h-[12rem] w-[94%] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-6 md:p-12 relative">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            View Booking Information
          </h3>
           <section>
           <div className="text-md mb-4 pl-3">
                 Date Booked: <span className="font-medium ml-3">
                    {`${new Date(exam?.booked_date).toLocaleDateString('en-ng')}`}
                 </span>
            </div>
            <div className="text-md mb-4 pl-3">
                 Time Booked: <span className="font-medium ml-3">
                    {`${convertTo12Hour(exam?.booked_time)}`}
                 </span>
            </div>
            <div className="text-md mb-4 pl-3">
                 State: <span className="font-medium ml-3">
                    {exam?.state}
                 </span> 
            </div>
            <div className="text-md mb-4 pl-3">
               Address: <span className="font-medium ml-3">
                    {exam?.address}
                 </span>
            </div>
            <div className="text-md mb-4 pl-3">
               Venue: <span className="font-medium ml-3">
                    {exam?.venue}
                 </span>
            </div>
           <div className="flex gap-x-10 justify-end">
              <button
                type="submit" onClick={() => closeModal(false)}
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-darkBlue text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Close
              </button>
            </div>
           </section>
        </div>
      </div>
    </div>
  );
}

export default ViewBookingModal;
