import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../../../../../api";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { AssessementContext } from "../../../AssessmentContext";
import { toast } from "react-hot-toast";
import { updatedSavedAnswerPayload } from "../../candidate-assessment/components/utils/transformSavedAnswerFormat";
import { flushSubmittedAnswers } from "../../../../../../features/candidate-exam/candidate-exam-slice";
import { toPlainAnwers } from "../../candidate-assessment/components/utils/functions";
//import { flushSubmittedAnswers } from "../../../../../features/candidate-exam/candidate-exam-slice";
import UnlockAssessmentModal from "./unlockAssessmentModal";
import { exams as examsApi } from "../../../../../../api/exams";
import VirtualExams from "./VirtualExams";
import PhysicalExams from "./PhysicalExams";
import Loader from "../../../../../../components/loader/Loader";
import Pagination from "../../../../../../components/Pagination/Pagination";
import Input from "../../../../../../components/input/Input";
import EmptyData from "../../../../../../components/EmptyData";

export const updateAnswers = ({
  answers, questions, updateAnswersMutation, 
  questionsDuration
}) => {
  updateAnswersMutation.mutate({
    userId: JSON.parse(sessionStorage.getItem("user"))?.user?.user_id || window.sessionStorage.getItem("user_id"),
    examId: window.sessionStorage.getItem("active-assessment-id"),
    userExamId: window.sessionStorage.getItem("active-assessment-user-id"),
    data: {
      ...updatedSavedAnswerPayload(
        [
          ...answers.map((answer) => {
            return {
              duration: answer?.duration,
              answer_id: answer?.answer_id,
              question_id: answer?.question_id,
              answer_text: answer?.answer_text,
            };
          }),
        ],
        questionsDuration,
        questions
      ),
      time_left: parseInt(sessionStorage.getItem("client_side_time_left")),
    },
  });
};

const UpcommingAssessment = () => {
  const formatTo12Hour = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    // Format minutes and seconds to always be two digits
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
    // Construct the formatted time string
    const formattedTime = `${hours}:${minutesStr}:${secondsStr} ${ampm}`;
    return formattedTime;
  };

  const [type, setType] = useState("virtual");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [search, setSearch] = useState('')
  const { assessment, setAssessment } = useContext(AssessementContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasExams, setHasExams] = useState(true);
  const [examLocked, setExamLocked] = useState(false);
  const [viewBookingModal, setViewBookingModal] = useState(false);
  const dispatch = useDispatch();
  const { questionSavedAnswers, questionsDuration, examQuestions } =
    useSelector((state) => state.candidateExam);
  const last_assessment_id = sessionStorage.getItem("last_asessement_id");
  const last_asessement_user_group_id = sessionStorage.getItem(
    "last_asessement_user_group_id"
  );

  // const mutation = useMutation(api.getUserGroupAssesment, {
  //   onSuccess: (data) => {
  //     setAssessment({
  //       ...assessment,
  //       assessements: { ...data?.data },
  //       activeQuestion: { ...data?.data?.questions[0] },
  //       questionType: data?.data?.questions[0]?.type,
  //       activeAssessment: {
  //         ...JSON.parse(window.sessionStorage.getItem("active-assessment")),
  //       },
  //     });
  //   },

  //   onError: (errorMessage) => {
  //     if (errorMessage.toString().includes("400")) {
  //       toast.error("Assessment needs to be unlocked by OTP or and admin");
  //     }
  //   },
  // });

  const {
    isLoading,
    data: exams,
    refetch,
  } = useQuery(
    ["user-group-assessment", type, currentPage, perPage, search ],
    async () => {
      const data = await examsApi.getAssesmentsv2(
        { type, id: user?.user?.user_id, currentPage, perPage, search }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.upcoming_exams?.length) {
          setHasExams(false);
        }
        setAssessment({ ...assessment, viewAsessments: { ...data?.data } });
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      select: res => res?.data
    }
  );

  const updateAnswersMutation = useMutation(examsApi.updateAnswersv2, {
    onSuccess: (response) => {
      dispatch(flushSubmittedAnswers());
      refetch();
    },
  });

  const getQuestionsmutation = useMutation(examsApi.getExamQuestionsv2, {
    onSuccess: (data) => {
      //compare between current question and prevoius saved questions
      const questions = data?.data?.questions?.filter((q) => {
        return q?.answers?.some((ans) => ans?.status === true);
      });
      if (examQuestions?.length) {
        if (questions?.length === 0) {
          //sync client answers
          const client_answers = toPlainAnwers(examQuestions);
          updateAnswers({
           answers: client_answers, questionsDuration,
           questions: data?.data?.questions, updateAnswersMutation
          });
        } else {
          //check for unsync questions and retry submitting
          const client_answers = toPlainAnwers(examQuestions);
          const server_answers = toPlainAnwers(questions);
          const unsynced_answers = client_answers.filter((client_answer) => {
            return !server_answers.some((server_answer) => {
              return (
                client_answer.question_id === server_answer.question_id &&
                client_answer.answer_id === server_answer.answer_id
              );
            });
          });
          //update answers
          if (unsynced_answers?.length){
            updateAnswers({
              answers: unsynced_answers, questionsDuration,
              questions: data?.data?.questions, updateAnswersMutation
             });
          }
        }
      }
    },
  });

  React.useEffect(() => {
    //update unsubmitted answers to sync with the backend.
    // if (last_assessment_id) {
    //   getQuestionsmutation.mutate({
    //     userId: user?.user?.user_id || window.sessionStorage.getItem("user_id"),
    //     examId: window.sessionStorage.getItem("active-assessment-id"), 
    //     userExamId: window.sessionStorage.getItem("active-assessment-user-id"),
    //     showOnlyQuestions: true,
    //     switchQuestion: false,
    //     level: sessionStorage.getItem("level") || 'easy',
    //   });
    // }
  }, []);



  useEffect(() => {
    const contactButton = document.querySelector(
      ".woot-widget-bubble.woot-elements--right"
    );
    //-------- hide and contact us button when exam is taken ----------------------//
    if (contactButton) {
      if (location.pathname.includes("candidate-assessment")) {
        contactButton.style.visibility = "hidden";
      } else {
        contactButton.style.visibility = "visible";
      }
    }
  }, [location.pathname]);

  const handleUserId = (exam) => {
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem("group_id", exam.group_id);
    sessionStorage.setItem("active-candidate-exam", JSON.stringify(exam));
    sessionStorage.setItem("exam-type", "normal");
    if (
      exam?.assessments?.length &&
      exam?.assessments[0]?.assessment_lock_status === 0
    ) {
      setExamLocked(true);
    } else {
      // if (exam?.require_booking && exam?.booking_status === 1) {
      if (type === "physical") {
        window.location.href =
          "https://candidate.speedexam.net/signin.aspx?site=setassessment";
      } else {
        navigate(`/candidate/assessments/candidate-exams/${exam?.group_id}`);
      }
    }
  };

  const handleClickExam = (exam) => {
    sessionStorage.setItem("group_id", exam.group_id);
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem("active-candidate-exam", JSON.stringify(exam));
    sessionStorage.setItem("exam-type", "normal");
    setViewBookingModal(true);
  };

  return (
    <>
      {" "} 
      {examLocked && (
        <UnlockAssessmentModal
          closeModal={setExamLocked}
          refetch={refetch}
          // getExamsMutation={getExamsMutation}
          // id={id}
        />
      )}
      { (
        <div>
          <div className="flex items-center justify-center mt-5">
            <div className="bg-slate-200 flex items-center justify-center gap-2 p-2 rounded-md">
              <button
                onClick={() => setType("virtual")}
                className={`text-md py-2 px-6 rounded-md ${
                  type === "virtual"
                    ? "text-white bg-slate-400"
                    : "hover:bg-slate-200"
                }`}
              >
                Online Exam
              </button>
              <button
                onClick={() => setType("physical")}
                className={`text-md py-2 px-6 rounded-md ${
                  type === "physical"
                    ? "text-white bg-slate-400"
                    : "hover:bg-slate-200"
                }`}
              >
                Assessment Day
              </button>
            </div>
          </div>
          <div className="text-white md:flex items-center justify-between bg-darkBlue my-4 py-4 px-4 mx-1 rounded-lg">
             <h5 className="max-[500px]:mb-3">
                Upcoming Exams &nbsp;{" "}
              ({exams?.meta?.total})
             </h5>
             <div className="w-[300px] text-slate-950">
                <Input 
                  type='search'
                  placeholder="Search exams"
                  onChange={ event => setSearch(event.target.value)}
                />
            </div>
          </div>
          { 
           isLoading ? <Loader /> :
           exams?.meta?.total ?
          <>
           {
            type === 'virtual' ? 
             <VirtualExams
               exams={exams} 
               isLoading={isLoading}
               currentPage={currentPage}
               perPage={perPage}
               setCurrentPage={setCurrentPage}
               setPerPage={setPerPage}
               refetch={refetch}
             />
             : <PhysicalExams
                  exams={exams?.upcoming_exams} isLoading={isLoading}
                  currentPage={currentPage}
                  perPage={perPage}
                  setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage}
                  refetch={refetch}
             />
          }
          </>
          : 
          <EmptyData 
              text={ 
                search !==  '' ? 'Sorry, no result found for your search'  : 
                'Sorry, you dont have any upcomming exam yet!'
                }
            /> 
         }
          <Pagination
              data={exams?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
          />
        </div>
      )}
    </>
  );
};

export default UpcommingAssessment;
