import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

type DiscreteSliderMarksType = {
  colorScheme: string
  value: number | number[],
  min: number,
  max: number,
}

const DiscreteSliderMarks: React.FC<DiscreteSliderMarksType> = ({
  colorScheme = '#8EAF55', value=20, min, max
}) => {

    const progressMarks = (min: number, max: number) => {
      const marks: { value: number, label: string }[] = []
        for (let i = 0; i <= 100; i += 10) {
          marks.push({
            value: i,
            label: i.toString() + '%',
          });
        }
      return marks
    }
    const valuetext = (value: number) : string => {
      return `${value}`;
    }

  return (
    <Box sx={{ width: '100%' }} >
      <Slider
        aria-label="Custom marks"
        defaultValue={value}
        getAriaValueText={valuetext}
        step={10}
        valueLabelDisplay="auto"
        marks={progressMarks(min, max)}
        className='pointer-events-none h-[10px] max-[500px]:-mt-3 opacity-80'
        sx={{
          '& .MuiSlider-rail': {
            backgroundColor: colorScheme,
          },
          '& .MuiSlider-track': {
            backgroundColor: colorScheme,
          },
           '& .MuiSlider-thumb': {
            color: '#fff', // Set white color for the middle thumb
            border: `2px solid ${colorScheme}`, // Optional: Add a border to make it visually distinct
            height: 12,
            width: 12,
          },
        }}
      />
    </Box>
  );
}

export default DiscreteSliderMarks
