import React from 'react'
import { AssessementContext } from '../../../AssessmentContext';
import { useNavigate } from 'react-router-dom'
import { api } from '../../../../../../api';
import ScaleLoader from '../../../components/ScaleLoader';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { clearRecentActiveExamData } from '../../../../../../features/candidate-exam/candidate-exam-slice';
import { useDispatch } from 'react-redux';
import { exams } from '../../../../../../api/exams';


const TimeOutModal = () => {

 const  { assessment, setAssessment } = React.useContext( AssessementContext )
 const navigate = useNavigate()
 const dispatch = useDispatch()

const finishExamMutation = useMutation(exams.gradeAssessmentv2,{
   onSuccess : () => {
      toast.remove();
       setAssessment({...assessment, isCompleted : true, savedAnswers: [], questionNumber: 0, isTimedOut : false  })
       dispatch( clearRecentActiveExamData() )
       sessionStorage.setItem('exam-submit-status', 0)
  },
  onError : () => {
     toast.remove();
     toast.error('Error! an unknown error occured.')
     setAssessment({...assessment, isTimedOut : true }) 
  },
  onMutate : () => {
   toast.loading("submitting exam, please wait...");
}
})

 const closeTimoutModal = () => {
    setAssessment({...assessment, isTimedOut : false })
    finishExamMutation.mutate({
      userId: user?.user?.user_id || window.sessionStorage.getItem("user_id"),
      examId: window.sessionStorage.getItem("active-assessment-id"), 
      userExamId: window.sessionStorage.getItem("active-assessment-user-id"),
      status: 'finished'
    })    
 }

  return (
    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
        <div className='w-full h-full flex items-center justify-center'>
            { finishExamMutation.isLoading && <ScaleLoader /> }
             <div className='modal-conetent bg-white w-[94%] md:w-[35%] h-[360px] rounded-lg'>
                  <h5 className='py-5 px-3 mb-5 shadow-lg text-xl'> Assessment Timeout </h5>
                 <div className='w-[80%] mx-auto h-[210px] mt-4 calc'>
                     <p className='flex justify-center'>
                        <span className='text-[100px] bi bi-clock-history text-red-500'>
                        </span>
                     </p>
                     <p className='text-xl text-center mt-3'>
                        You had reached out your assessment time,
                        your assessment is automatically getting submitted.
                     </p>
                 </div>
                <div className='flex justify-center border-t boredr-t-2 border-gray-200 mt-5 px-3 py-3'>
                         <button onClick={closeTimoutModal}  className='bg-red-500 py-3 px-16 rounded-lg text-white text-lg'>
                              End Assessment
                        </button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default TimeOutModal