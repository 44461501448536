import React, { useMemo, useState } from 'react'
import { UpdatedRequestTable } from '../../../../../../components/table/RequestTable';
import { convertTo12Hour } from '../../../../../../utils/dateTimeFormatter';
import { useNavigate } from 'react-router-dom';
import { clearFlaggedQuestion } from '../../../../../../features/candidate-exam/candidate-exam-slice';
import { useDispatch } from 'react-redux';
import BookexamModal from './BookexamModal';
import UnlockAssessmentModal from './unlockAssessmentModal';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import ViewBookingModal from './ViewBookingModal';

const PhysicalExams = ({ 
           exams, isLoading, currentPage, setCurrentPage, perPage, setPerPage,
           refetch,
   }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [requireBooking, setRequireBooking] = useState(false)
    const [openViewBookingModal, setOpenViewBookingModal] = useState(false)
    const [isLocked, setIsLocked] = useState(false)
    const [currentExam, setCurrentExam] = useState(null)
    const handleActionClick = (row) => {
        console.log(row, 'row');
        sessionStorage.setItem('active-exam',  JSON.stringify(row))
        dispatch(clearFlaggedQuestion());
        window.sessionStorage.setItem("active-assessment-id", row?.exam_id);
        window.sessionStorage.setItem("active-assessment-user-id", row?.user_exam_id);
        window.sessionStorage.setItem("active-assessment", JSON.stringify(row));
        window.sessionStorage.setItem("time_left", row?.time_left);
        window.sessionStorage.setItem("duration", row?.exam_duration);
        window.sessionStorage.setItem("ql", row?.number_of_questions);
        window.sessionStorage.setItem("starting-index", row?.number_of_questions_answered);
         // exam requires booking and candidate haven't booked 
        if(row?.require_booking && !row?.booked_at){
          setRequireBooking(true);
         }
         else {
           // exam is already booked
           if(row?.booked_at){
              // check if exam is locked
              if(row?.exam_is_locked) {
                return setIsLocked(true)
              }
           } 
           if(row?.redirect_to_speed_exam){
            window.location.href = "https://candidate.speedexam.net/signin.aspx?site=setassessment";
           }else{
            navigate("/candidate/assessments/instructions");
           }
         }
    }

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((exams?.current_page || 1) - 1) *
                (exams?.per_page || perPage),
          },
          {
            title: "Exam Name",
            selector: "exam_name",
            key: "exam_name",
            render: ({ item, row }) => {
              return (
                <button
                  className="text-baseFont underline text-primary"
                  onClick={ () => handleActionClick(row) }
                >
                  {item}
                </button>
              );
            },
          },
          {
            title: "Duration",
            selector: "exam_duration",
            key: "exam_duration",
            render: ({ item }) => {
                return (
                  <button
                    className="text-baseFont"
                  >
                    {item} mins 
                  </button>
                );
              },
          }, 
          {
            title: "Questions",
            selector: "number_of_questions",
            key: "number_of_questions",
            render: ({ item }) => {
                return (
                  <button
                    className="text-baseFont pl-3"
                  >
                    {item} 
                  </button>
                );
              },
          },
          {
            title: "Start Date",
            selector: "start_date",
            key: "start_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString('en-ng')}`}</span>
            }
          },
          {
            title: "End Date",
            selector: "end_date",
            key: "end_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString('en-ng')}`}</span>
            }
          },
          {
            title: "Start Time",
            selector: "start_time",
            key: "start_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "End Time",
            selector: "end_time",
            key: "end_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "Action",
            selector: "end_time",
            key: "end_time",
            render: ({item, row}) => {
              if(row?.booked_at){
                 return  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <button
                          className="text-sm bi bi-three-dots-vertical font-semibold" 
                          aria-label="Customise options" 
                      />
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Portal>
                      <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                      <DropdownMenu.Item
                          className="DropdownMenuItem text-left  hover:bg-gray-200 text-primary-blue"
                          onClick={ () => handleActionClick(row) }
                      >
                          {
                           (!row?.require_booking || row?.booked_at) ? 
                             row?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                           'Book Exam'
                          }
                      </DropdownMenu.Item>
                      <DropdownMenu.Separator className="DropdownMenuSeparator" />
                      <DropdownMenu.Item 
                          className="DropdownMenuItem text-left hover:bg-gray-200 text-primary-blue"
                          onClick={ () => {
                            setRequireBooking(true);
                          }}
                      >
                          <i className='pr-1' /> Update/Change Booking
                      </DropdownMenu.Item>
                      <DropdownMenu.Separator className="DropdownMenuSeparator" />
                      <DropdownMenu.Item 
                          className="DropdownMenuItem text-left hover:bg-gray-200 text-primary-blue"
                          onClick={ () => {
                            setCurrentExam(row)
                            setOpenViewBookingModal(true);
                          }}
                      >
                          <i className='pr-1' /> View Booking Info
                      </DropdownMenu.Item>
                      </DropdownMenu.Content>
                  </DropdownMenu.Portal>
              </DropdownMenu.Root>
              }
              return <button 
                       className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                       onClick={ () => handleActionClick(row) }
                      >
                        {
                           (!row?.require_booking || row?.booked_at) ? 
                             row?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                           'Book Exam'
                          }
                    </button>
            }
          },
     ], [exams?.current_page, exams?.per_page, perPage])

  return (
    <div className='p-3 rounded-lg bg-white'>
      {
        openViewBookingModal ?
        <ViewBookingModal exam={currentExam} closeModal={setOpenViewBookingModal} />
        : null
      }
         { requireBooking ?
           <BookexamModal 
             closeModal={setRequireBooking} 
             refetch={refetch}
           />
           : null
         }
         {
           isLocked ?
             <UnlockAssessmentModal 
                refetch={refetch} closeModal={setIsLocked} 
             />
           : null
         }
        <div className='max-[500px]:hidden'>
          <UpdatedRequestTable
              columns={columns}
              data={exams}
              isLoading={isLoading}
        />
        </div>
       <div className='md:hidden grid grid-cols-1 gap-5'>
           {
              exams?.length ? 
               exams?.map( exam => {
                 return (
                  <ul key={exam?.id + 1} className="border border-gray-200 rounded-lg mb-8 p-0">
                  <li className="flex justify-between py-3 px-4">
                     <span className="text-lg">Exam Name:&nbsp;</span>
                     <span className="text-lg text-primary underline"
                     onClick={ () => handleActionClick(exam) }
                      >
                        {exam?.exam_name}
                     </span>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Duration:&nbsp;</span>
                     <span className="text-lg">
                     {exam?.exam_duration}
                     </span>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                     <span className="text-lg">Questions:&nbsp;</span>
                     <span className={`text-lg`}>
                        {exam?.number_of_questions}
                     </span>
                  </li>
                  <li className="flex  bg-gray-100 justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.start_date).toLocaleDateString('en-ng')}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.end_date).toLocaleDateString('en-ng')}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                       {`${convertTo12Hour(exam?.start_time)}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                        {`${convertTo12Hour(exam?.end_time)}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Action:&nbsp;</span>
                      {
                      (exam?.booked_at)  ? <DropdownMenu.Root>
                             <DropdownMenu.Trigger asChild>
                               <button
                                   className="text-sm bg-primary text-white py-3 px-5 font-semibold" 
                                   aria-label="Customise options" 
                               >View More</button>
                                   </DropdownMenu.Trigger>
                                   <DropdownMenu.Portal>
                               <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                               <DropdownMenu.Item
                                   className="DropdownMenuItem text-left  hover:bg-gray-200 text-primary-blue"
                                   onClick={ () => handleActionClick(exam) }
                               >
                                   {
                                    (!exam?.require_booking || exam?.booked_at) ? 
                                      exam?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                                    'Book Exam'
                                   }
                               </DropdownMenu.Item>
                               <DropdownMenu.Separator className="DropdownMenuSeparator" />
                               <DropdownMenu.Item 
                                   className="DropdownMenuItem text-left hover:bg-gray-200 text-primary-blue"
                                   onClick={ () => {
                                     setRequireBooking(true);
                                   }}
                               >
                                   <i className='pr-1' /> Update/Change Booking
                               </DropdownMenu.Item>
                               <DropdownMenu.Separator className="DropdownMenuSeparator" />
                               <DropdownMenu.Item 
                                   className="DropdownMenuItem text-left hover:bg-gray-200 text-primary-blue"
                                   onClick={ () => {
                                     setCurrentExam(exam)
                                     setOpenViewBookingModal(true);
                                   }}
                               >
                                   <i className='pr-1' /> View Booking Info
                               </DropdownMenu.Item>
                               </DropdownMenu.Content>
                           </DropdownMenu.Portal>
                       </DropdownMenu.Root>
                       :
                        <button 
                                className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                                onClick={ () => handleActionClick(exam) }
                               >
                                 {
                                    (!exam?.require_booking || exam?.booked_at) ? 
                                      exam?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                                    'Book Exam'
                                   }
                             </button>
                       }
                  </li>
               </ul>
                 )
               } )
              : null
           }
        </div>
    </div>
  )
}

export default PhysicalExams