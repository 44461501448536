import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import EmptyData from "../../../../components/EmptyData";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { exams } from "../../../../api/exams";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { clearFlaggedQuestion } from "../../../../features/candidate-exam/candidate-exam-slice";

const Table = ({examHistory, search}) => {  

  const {isLoading, mutateAsync} = useMutation(exams.retakeExam)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const retakeExam = async (examId) => {
    try {
      const response = await mutateAsync({examId, userId: sessionStorage.getItem('user_id')});
              if(response?.data?.redirect_to_results){
                return toast.error('You have reach the maximum attemp limit')
              }
               dispatch(clearFlaggedQuestion(null));
               sessionStorage.setItem("user_id", response?.data?.user?.user_id )
               window.sessionStorage.setItem("active-assessment-id", response?.data?.user?.exam_id);
               window.sessionStorage.setItem("active-assessment-user-id", response?.data?.user?.user_exam_id);
               window.sessionStorage.setItem("active-assessment", JSON.stringify(response?.data?.exam));
               window.sessionStorage.setItem("time_left", response?.data?.exam?.time_left);
               window.sessionStorage.setItem("duration", response?.data?.exam?.duration);
               window.sessionStorage.setItem("ql", response?.data?.exam?.number_of_questions);
               window.sessionStorage.setItem("starting-index", response?.data?.exam?.number_of_questions_answered);
              // navigate("/candidate/assessments/instructions");
              if(response?.data?.exam?.number_of_attempts) {
                sessionStorage.setItem('attempt_count', response?.data?.exam?.number_of_attempts)
              }  
              navigate(`/candidate/assessments/instructions`)
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <React.Fragment>
        <div

          style={{ border: "1px solid #ccc" }}
        >
          {examHistory.length ?
          <div className="md:hidden mt-3 bg-white w-full p-4 rounded-md">
            {examHistory.map((exam, index) => (
                      <ul className="border border-gray-300 rounded-lg mb-8 p-0" key={index}>
                          <li className="flex justify-between py-3 px-4">
                            <span className="text-lg">Title:&nbsp;</span>
                            <span className="text-lg">
                              { exam?.exam?.title}
                            </span>
                          </li>
                          <li className="flex justify-between py-4 px-4">
                            <span className="text-lg">Client Name:&nbsp;</span>
                            <span className="text-lg">
                              { exam?.exam?.client?.name }
                            </span>
                          </li>
                          <li className="flex justify-between  py-4 px-4">
                            <span className="text-lg">Status:&nbsp;</span>
                            <span className="text-lg">
                            {
                                (exam?.completed_at !== null)
                                ?  <span className="bi bi-check2-circle text-green-500 text-2xl"></span>
                                :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                              }                                 
                            </span>
                          </li>
                          <li className="flex justify-between py-4 px-4">
                            <span className="text-lg">Completed At&nbsp;</span>
                            <span className="text-lg">
                              { `${ new Date(exam?.completed_at).toLocaleDateString('EN-gb') }` }
                            </span>
                          </li>
                          <li className="flex justify-between py-4 px-4">
                            <span className="text-lg">Action&nbsp;</span>
                             <div className="flex flex-nowrap">
                             <button to={`/candidate/results/${exam?.exam_id}`} className="text-white bg-darkBlue mr-3 hover:bg-darkBlue-focus focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
                                  onClick={ () => retakeExam(exam?.exam_id) }
                                >
                                   {isLoading ? 'Loading...' : 'Retake Exam'}
                                </button> 
                                <Link to={`/candidate/results/${exam?.exam_id}`} className="text-white bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
                                  onClick={ () => sessionStorage.setItem('activeExam', exam?.exam?.title) }
                                >
                                   View Result  
                                </Link>
                             </div>
                          </li>
                      </ul>
                ))  }                
        </div>
        : 
        <section className="md:hidden">
            <EmptyData 
              text={ 
                search !==  '' ? 'Sorry, no result found for your search'  : 
                'Sorry, you dont have any exam history yet!'
                }
            /> 
        </section>  
        }
         {
           ( examHistory.length ) ?
            <div class="flex bg-white flex-col overflow-x-auto m-hidden">
            <div class="sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div class="overflow-x-auto">
                  <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium dark:border-neutral-500">
                      <tr>                         
                        <th scope="col" class="px-6 py-4">S/N</th>                        
                        <th scope="col" class="px-6 py-4">Title</th>
                        <th scope="col" class="px-6 py-4">Client Name</th>
                        <th scope="col" class="px-6 py-4">Status</th>
                        <th scope="col" class="px-6 py-4">Completed At</th>     
                        <th scope="col" class="px-6 py-4">Action</th>
                      </tr>
                    </thead>
                    <tbody>                             
                      {
                        examHistory.length ? examHistory.map( (exam, id) => {
                          return(
                            <tr key={ id } className="border-b dark:border-neutral-500">                            
                              <td className="whitespace-nowrap pl-8  pr-6 py-4 font-medium"> {id + 1} </td>
                              <td className="whitespace-nowrap px-6 py-4"> { exam?.exam?.title} </td>
                              <td className="whitespace-nowrap px-6 py-4"> { exam?.exam?.client?.name } </td>
                              <td className="whitespace-nowrap px-6 py-4">  
                                  <span className="text-lg">
                                  {
                                      (exam?.completed_at !== null)
                                      ?  <span className="bi bi-check2-circle text-green-500 text-2xl"></span>
                                      :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                                    }
                                  </span> 
                               </td>
                              <td className="whitespace-nowrap px-6 py-4"> { `${ new Date(exam?.completed_at).toLocaleDateString('EN-gb') }` } </td> 
                              <td className="whitespace-nowrap flex flex-nowrap py-4">
                                <button to={`/candidate/results/${exam?.exam_id}`} className="text-white bg-darkBlue mr-3 hover:bg-darkBlue-focus focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
                                  onClick={ () => retakeExam(exam?.exam_id) }
                                >
                                   Retake Exam
                                </button> 
                                <Link to={`/candidate/results/${exam?.exam_id}`} className="text-white bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center"
                                  onClick={ () => sessionStorage.setItem('activeExam', exam?.exam?.title) }
                                >
                                   View Result  
                                </Link>  
                              </td>                    
                          </tr>
                          )
                          })
                          : null
                        }              
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
           </div>
           :
            <section className="max-[500px]:hidden">
                <EmptyData 
                    text={ 
                      search !== '' ? 'Sorry, no result found for your search'  : 
                      'Sorry, you dont have any exam history yet!'
                      }
                  />
            </section>                
          }           
        </div>
    </React.Fragment>
  );
};

export default Table;
