import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AssessementContext } from "../../AssessmentContext";
import { useContext } from "react";
import { api } from "../../../../../api";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import ScaleLoader_ from "../../components/ScaleLoader";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../../../components/customtoast/CustomToastProvider";
import { exams } from "../../../../../api/exams";
import { updateAnswers } from "../view-assessement/components/UpcomingAssessment";
import { useSelector } from "react-redux";
import { exams as examsApi } from "../../../../../api/exams";
import { toPlainAnwers } from "../candidate-assessment/components/utils/functions";
const Instructions = ({ start, restart }) => {

  const { assessment, setAssessment } = useContext(AssessementContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customToast = useCustomToast();
  const user = JSON.parse(sessionStorage.getItem('user'))
  const time_left = parseInt(window.sessionStorage.getItem("time_left"));
  const duration = parseInt(window.sessionStorage.getItem("duration"));
  const last_question_viewed = parseInt(
    sessionStorage.getItem("last_question_viewed")
  );
  const time_in_minutes = (time) => Math.floor(time / 60);
const { questionSavedAnswers, questionsDuration, examQuestions } =
    useSelector((state) => state.candidateExam);

  const unsupportedActions = [
    "Minimizing the browser",
    "Resizing the browser",
    "Open a new tab",
    "Open A New Program",
    "Pressing Ctrl + C",
    "Pressing Ctrl + V",
    "Pressing Print Screen",
    "Pressing F12",
  ];

  const updateAnswersMutation = useMutation(examsApi.updateAnswersv2, {
      onSuccess: (response) => {
        dispatch(flushSubmittedAnswers());
        refetch();
      },
    });

  const getQuestionsmutation = useMutation(exams.getExamQuestionsv2, {
    onSuccess: (data) => {
      if(sessionStorage.getItem("rejoinUser") === '1'){
              sessionStorage.setItem("rejoinUser", '0')
           //compare between current question and prevoius saved questions
                const questions = data?.data?.questions?.filter((q) => {
                  return q?.answers?.some((ans) => ans?.status === true);
                });
                if (examQuestions?.length) {
                  if (questions?.length === 0) {
                    //sync client answers
                    const client_answers = toPlainAnwers(examQuestions);
                     updateAnswers({
                      answers: client_answers, questionsDuration,
                      questions: data?.data?.questions, updateAnswersMutation
                    });
                  } else {
                    //check for unsync questions and retry submitting
                    const client_answers = toPlainAnwers(examQuestions);
                    const server_answers = toPlainAnwers(questions);
                    const unsynced_answers = client_answers.filter((client_answer) => {
                      return !server_answers.some((server_answer) => {
                        return (
                          client_answer.question_id === server_answer.question_id &&
                          client_answer.answer_id === server_answer.answer_id
                        );
                      });
                    });
                    //update answers
                    if (unsynced_answers?.length){
                       updateAnswers({
                        answers: unsynced_answers, questionsDuration,
                        questions: data?.data?.questions, updateAnswersMutation
                      });
                    }
                  }
                }
            return
         }
       else {
        if(!data?.data?.questions?.length){
          toast.error(`Error! there is no any question`)
          console.log(data, 'data');
          
       }
       else{
         setAssessment({
           ...assessment,
           assessements: { questions: data?.data?.questions },
           activeQuestion: { ...data?.data?.questions[0] },
           questionType: data?.data?.questions[0]?.type,
           activeAssessment: {
             ...JSON.parse(window.sessionStorage.getItem("active-assessment")),
           },
         });
         setTimeout(
           () => navigate("/candidate/assessments/candidate-assessment"),
           100
         );
       }
       }
    },
    onError: ({ response }) => {
      toast.remove();
      customToast.error(response?.data?.message);
    },
  });

  React.useEffect(() => {
    const container = document.querySelector(`#parent-container`);
    container.scrollTo({ top: 0 });
    sessionStorage.setItem("exam-submit-status", 0);
    sessionStorage.setItem("device-width", window.innerWidth);
    sessionStorage.setItem("device-height", window.innerHeight);
    setAssessment({ ...assessment, isAutomove: false });
    // validate and sync if user is coming from rejoin page
    if(sessionStorage.getItem("rejoinUser") === '1'){
        getQuestionsmutation.mutate({
        userId: user?.user?.user_id || window.sessionStorage.getItem("user_id"),
        examId: window.sessionStorage.getItem("active-assessment-id"), 
        userExamId: window.sessionStorage.getItem("active-assessment-user-id"),
        showOnlyQuestions: false,
      });
    }
  }, []);

  const startAssessment = () => {
    if (time_left !== duration && time_left !== null && !isNaN(time_left)) {
      if (time_left === 0) {
        restart(new Date().getTime() + time_left * 60 * 1000);
      } else restart(new Date().getTime() + time_left * 60 * 1000);
    } else {
      restart(new Date().getTime() + duration * 60 * 1000);
    }

    setAssessment({ ...assessment, startAssessment: true });
    sessionStorage.setItem("full_screen_mode", 1);
    const element = document.documentElement; // Get the root element of the document
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    //hide sidebar and
    // setIsOpen(false)
    document.querySelector("#sidebar").style.display = "none";
    //hide navbar in mobile devices
    if (window.matchMedia("(max-width: 700px)").matches) {
      document.querySelector(".mobile-navbar").style.display = "none";
    }
    setTimeout(() => {
      getQuestionsmutation.mutate({
        userId: user?.user?.user_id || window.sessionStorage.getItem("user_id"),
        examId: window.sessionStorage.getItem("active-assessment-id"), 
        userExamId: window.sessionStorage.getItem("active-assessment-user-id"),
        showOnlyQuestions: false,
      });
    }, 1000);
  };

  return (
    <div>
      {" "}
      {getQuestionsmutation?.isLoading ? <ScaleLoader_ /> : null}
      <section className="jumbotron bg-white p-4">
        <Link
          to={"/candidate/assessments/view-assessments"}
          className="text-lg"
        >
          Exam
        </Link>
        <span className="mx-2">/</span>
        <Link
          to={"/candidate/assessments/instructions"}
          className="text-lg text-customGreen"
        >
          instructions
        </Link>
        <div className="mt-8 py-12 bg-darkBlue px-5 text-white rounded-lg">
          <h5 className="text-2xl flex">
            {
               assessment?.activeAssessment?.assessment_title
              || JSON.parse(window.sessionStorage.getItem("active-assessment"))?.title
            }
          </h5>
          <p className="text-[12px]">please attempt all questions.</p>
          <div className="mt-4 bg-white py-4 rounded-lg text-gray-900 px-4 flex justify-between">
            <span>
              Total Questions{" "}
              {sessionStorage.getItem("ql")}
            </span>
            <span className="text-xl flex items-center">
              <i className="text-2xl bi bi-clock-history mr-2"></i>{" "}
               {duration}
              minutes
            </span>
          </div>
        </div>
        <div className="mt-5">
          <h5 className="text-2xl">
            {" "}
            <strong className="text-2xl">Instructions:</strong>{" "}
          </h5>
          <h4 className="text-lg text-red-500">
            Please read the following instructions carefully:
          </h4>
          <p className="mt-3 text-lg">
            Ensure your assessment is on a full screen.
          </p>
          <p className="mt-3 text-lg">
            1.&nbsp; In the situation you encounter a frozen screen or the next
            question is not loading, It is recommended that you close the tab
            and sign in again to prevent any further time wastage.
          </p>
          <p className="mt-3 text-lg">
            2. &nbsp; In the situation your screen is not properly sized and
            questions/answers aren't completely visible or properly displayed,
            Log out, resize the browser window, and then log back in OR Log out
            and switch to a different browser OR Log out and switch to a
            different device.
          </p>
          <p className="mt-3 text-lg">
            3. &nbsp; This assessment is time-limited, and as such, a timer will
            be initiated. Once the timer elapses, the exam will end
            automatically. It is recommended that you manage your time
            effectively to complete the assessment within the allocated time.
          </p>
          <p className="mt-3 text-lg">
            4. &nbsp; For the optimal experience, it is recommended that you
            take this assessment using a laptop, or desktop computer with a
            stable internet connection.
          </p>
          <p className="mt-3 text-lg">
            5. &nbsp; If you come across any questions that are missing answers
            or have been written incorrectly, please utilize the flag button,
            and we will take note of your concerns.
          </p>
          <p className="mt-3 text-lg">
            6. &nbsp; Please refrain from engaging in any unusual activities
            such as opening other applications, minimizing the assessment tab,
            or attempting to copy the questions. Each of these actions will be
            considered as one unusual activity, and your assessment will be
            terminated if you accumulate five counts of such activities. Taking
            the assessment on your mobile device and receiving calls, messages,
            or notifications may increase your likelihood of accumulating
            unusual activities.
          </p>
          <h4 className="text-2xl text-red-500 mt-5">
            Secure Assessment Advice:
          </h4>
          <p className="mt-1 text-lg">
            This assessment is specially secured. This means you cannot leave
            your browser window once the assessment starts
          </p>
          <strong className="text-xl block my-3">
            The following actions are not supported during your assessment.
          </strong>
          <ul className="py-5 px-3 bg-red-100 border border-red-500 rounded-lg">
            {unsupportedActions.map((unsupportedAction, id) => {
              return (
                <li
                  key={id}
                  className="mt-3 text-lg capitalize text-red-500 flex items-center"
                >
                  <span className="text-xl bi bi-exclamation-triangle ml-3 mr-2"></span>
                  {unsupportedAction}
                </li>
              );
            })}
          </ul>
          <div className="mt-8 flex justify-end mb-5">
            <button
              onClick={startAssessment}
              disabled={getQuestionsmutation.isLoading}
              className="border flex justify-center bg-red-500 text-white py-3 block  w-[300px] rounded-lg"
            >
              <span
                className="text-lg text-center"
                data-testid="assessment-instruction"
              >
                {" "}
                Ok, I Understand{" "}
              </span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instructions;
