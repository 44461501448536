import React from 'react'
import { AssessementContext } from "./../../../AssessmentContext";
import { useSelector } from 'react-redux';


const QuestionIndexGotoModal = ({id, questions, activeQuestion, gotoQuestion, isLoading }) => {

    const { assessment, } = React.useContext( AssessementContext )
    const { flaggedQuestions } = useSelector( state => state.candidateExam)

    React.useEffect(() => {
        const pos = document.querySelector(`#exam-questions-index-${activeQuestion}`)
        const examQuestionsViewPanel = document.querySelector(`#exam-questions-view-panel${activeQuestion}`)
       if( activeQuestion > 10 ) examQuestionsViewPanel.scrollTo({
           top: pos.offsetTop - 5,
           behavior:'instant'
        })
    }, [activeQuestion, id, questions])

  return (
    <>  
      <div className="fixed w-[50px] md:w-[60px] z-[10] h-[100vh] top-[0px] left-0"
      >
          <div 
             id={'exam-questions-view-panel' + activeQuestion}
             className='flex-column flex-auto p-1 md:p-4 h-full overflow-y-auto exam-questions-view-panel overflow-x-hidden'
          >
                {
                    questions.map( (question,index) => {
                       const isAnswerChoosen = assessment.savedAnswers.map(answer => answer.question_id);
                       const isFlagged = flaggedQuestions?.includes(question.id)
                       const questionSynced = question?.answers?.filter( answer => answer?.status === true )
                        
                       return (
                            <button 
                                key = {index} 
                                id={'exam-questions-index-'+ index}
                                onClick={ () => {
                                   gotoQuestion(index)
                                } }   
                                style={{
                                  pointerEvents: (sessionStorage.getItem('examType') === 'adaptive' 
                                  && (activeQuestion < index) && isLoading 
                                ) ? 'none' : 'auto'
                                }}                             
                                className={`
                                     ${  isFlagged ? 'bg-red-600 text-white' :
                                         activeQuestion === index ? 'bg-green-500 text-white' :
                                        ( isAnswerChoosen.includes(question.id) || 
                                          questionSynced?.length
                                         ) ? 'bg-yellow-600 text-white' 
                                            : 'un-answered-questions text-gray-900'
                                     } 
                                     inline-block px-3 md:px-4 py-2 bg-gray-300 rounded text-md md:text-xl mb-5`
                                    }
                                type="button"
                            >  { index + 1 } </button>
                        )
                    })
                }
          </div>
      </div>
    </>
  )
}

export default QuestionIndexGotoModal