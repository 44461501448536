import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from "../../images/TestAssessify.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { greetTime } from '../account/login';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { api } from '../../api/jointExam';
import Loader from '../../components/loader/Loader';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { clearFlaggedQuestion } from '../../features/candidate-exam/candidate-exam-slice';
import { sortFields } from '../candidate/assessments/components/utils';

type viewType = 'rejoin' | 'join' | null
const JointExam = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [view, setView] = useState<viewType>('rejoin')
    const [rejoinCode, setRejoinCode] = useState<string>('')
    const [defaultEmail, setDefaultEmail] = useState<string>('')
    const [selectOptions, setSelectOptions] = useState<Record<string, any[]>>({});
    const { id } = useParams();

    // Allowed domains (lowercase for comparison)
    const allowedDomains = [
      'accessbankplc.com',
      'theaccesscorporation.com',
      'accessplc.onmicrosoft.com',
      'accessplc.com',
      'accessbankplc.co.tz' // Note: converted to lowercase
  ];

    // const fetchSelectOptions = async (fieldKey: string, dataPath: string) => {
    //   try {
    //     const response = await api.get(dataPath); // You'll need to implement this API call
    //     setSelectOptions(prev => ({
    //       ...prev,
    //       [fieldKey]: response.data
    //     }));
    //   } catch (error) {
    //     console.error(`Error fetching options for ${fieldKey}:`, error);
    //   }
    // };    

    const emailDomainErrorMessage = 'Email must be from one of the access bank domains, e.g. jhon@accessbankplc.com'
    const generateValidationSchema = (formFields: any) => {
        const schema: any = {};     
        formFields.forEach((_field: any) => {
          const {field} = _field;
          switch (field.field_type) {
            case 'string':
              schema[field.field_key] = Yup.string().required(`${field.field_name} is required`);
              break;
            case 'number':
              schema[field.field_key] = Yup.number().required(`${field.field_name} is required`);
              break;
            case 'email':
              schema[field.field_key] = Yup.string()
              .email('Invalid email')
              .required(`${field.field_name} is required`)
              .test(
                  'valid-domain',
                  emailDomainErrorMessage,
                  (value) => {
                      if (!value) return false;
                      
                      // Extract domain part (case-insensitive)
                      const domain = value.split('@')[1]?.toLowerCase();
                      
                      if (!domain) return false;
                      
                      return allowedDomains.includes(domain);
                  }
              );
              break;
            // Add more cases as needed
            default:
              schema[field.field_key] = Yup.string().required(`${field.field_name} is required`);
          }
        });
      
        return Yup.object().shape(schema);
      };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const {mutateAsync, isLoading: isSubmitting} = useMutation(api.loginToExam)
    const {mutateAsync: checkEmail, isLoading: isSubmittingCheckEmail} = useMutation(api.checkExam)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { data: subsidiaries, isLoading: isLoadingSubs } = useQuery(
      ["Subsidiaries"],
      async () => {
        const data = await api.getSelections({ path:'/subsidiaries' });
        return data;
      },
      {
        select: (response) => response?.data,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,

      },
    );

    const { data: countries, isLoading: isLoadingCountries } = useQuery(
      ["countries"],
      async () => {
        const data = await api.getSelections({ path:'/countries' });
        return data;
      },
      {
        select: (response) => response?.data,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,

      },
    );

    const { data: grades, isLoading: isLoadingGrades } = useQuery(
      ["grades"],
      async () => {
        const data = await api.getSelections({ path:'/grades' });
        return data;
      },
      {
        select: (response) => response?.data,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,

      },
    );
  
    const { data: userData, isLoading } = useQuery(
      ["User Data"],
      async () => {
        const data = await api.getExam({ id: id as string });
        return data;
      },
      {
        select: (response) => response?.data,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onError: (error:any) => {
            if(
                error?.response?.data?.message === 'Exam does not exist'
                 && error?.response?.status === 400
            ){
              navigate('/page-not-found')
            }
        }
      },
    );

    const successAction = (response: any) : void => {
        toast.success('Candidate verified successfully!');
        sessionStorage.setItem("userToken", response.data.token);
        sessionStorage.setItem("guestUserExamId", id as string);
        dispatch(clearFlaggedQuestion(null));
        sessionStorage.setItem("user_id", response?.data?.user?.user_id )
        window.sessionStorage.setItem("active-assessment-id", response?.data?.user?.exam_id);
        window.sessionStorage.setItem("active-assessment-user-id", response?.data?.user?.user_exam_id);
        window.sessionStorage.setItem("active-assessment", JSON.stringify(response?.data?.exam));
        window.sessionStorage.setItem("time_left", response?.data?.exam?.time_left);
        window.sessionStorage.setItem("duration", response?.data?.exam?.duration);
        window.sessionStorage.setItem("ql", response?.data?.exam?.number_of_questions);
        window.sessionStorage.setItem("starting-index", response?.data?.exam?.number_of_questions_answered);
       // navigate("/candidate/assessments/instructions");
        sessionStorage.setItem("user", JSON.stringify(response?.data?.user));
        if(response?.data?.redirect_to_results){
          return navigate('/candidate/exam-history')
        }
        if(response?.data?.exam?.number_of_attempts >= 1 && response?.data?.exam?.number_of_attempts <= 3){
          sessionStorage.setItem('attempt_count', response?.data?.exam?.number_of_attempts)
          navigate(`/candidate/assessments/instructions`)
        }else {
          navigate('/candidate/exam-history')
        }
    
        sessionStorage.setItem("loginType", 'join');
        if(view === 'rejoin') sessionStorage.setItem("rejoinUser", '1')
    } 
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: userData?.formFields?.reduce((acc: any, _field: any) => {
        const {field} = _field;
        acc[field.field_key] = '';
        return acc;
      }, {}) || {},
      validationSchema: generateValidationSchema(userData?.formFields || []),
      onSubmit: async (values) : Promise<void> => {
        try {
             // const 
              const response = await mutateAsync({
                ...values, exam_id: id,
                returning_user: false,
                existing_user: false,
                other_fields: [
                  {
                    demography_id: userData?.formFields?.find((field: any) => field?.field?.field_key === 'grade')?.field?.id,
                    value: values?.grade
                  },
                  {
                    demography_id: userData?.formFields?.find((field: any) => field?.field?.field_key === 'subsidiary')?.field?.id,
                    value: values?.subsidiary
                  },
                  {
                    demography_id: userData?.formFields?.find((field: any) => field?.field?.field_key === 'country')?.field?.id,
                    value: values?.country
                  }
                ],
               // resumption_code: userData?.resumption_code
              });
            successAction(response)
            }
           catch (error: any) {
            toast.remove();
            toast.error(error?.response?.data?.message);
          }
      },
    });


    const rejoinExam = async () : Promise<any> => {
      const domain = defaultEmail.split('@')[1]?.toLowerCase();
      //setView('join')
       if(!defaultEmail){
            return toast.error('Error! email is required.')
        }
        else if (!domain || !allowedDomains.includes(domain)) {
          toast.error(emailDomainErrorMessage);
          return; // Stop if domain is invalid
      }
        try {
          formik.setFieldValue('email', defaultEmail)
          const response = await checkEmail({
              email: defaultEmail,
              client_id: userData?.exam?.client_id,
          });
         // successAction(response)
          if(response?.data?.is_existing){
            const response = await mutateAsync({
              exam_id: id,
              email: defaultEmail,
              existing_user: true,
            });
           successAction(response)
          }
          else {
            setView('join')
          }
        }
         catch (error: any) {
          toast.remove();
          toast.error('Error! Please reload the page and try again.');
        }
        // if(!rejoinCode){
        //     return toast.error('Error! resumption code is required.')
        // }
        // try {
        //     const response = await mutateAsync({
        //       exam_id: id,
        //       returning_user: true,
        //       resumption_code: rejoinCode
        //   });
        //   successAction(response)
        //   }
        //  catch (error: any) {
        //   toast.remove();
        //   toast.error(error?.response?.data?.message);
        // }
    }
  
    if (isLoading) return <Loader />;
  
    return (
      <>
        <div className="bg-white p-4 lg:p-6 ">
          <div className="w-full pb-4 h-26 bg-inherit sticky top-0 cursor-pointer">
            <div className="h-full">
              <Link to={'/'}>
                <img
                    src={logo}
                    alt="Test Assessify"
                    className="w-56 h-full object-contain"
                />
              </Link>
            </div>
          </div>
          <div className="flex justify-center items-center  2xl:mt-0  2xl:h-screen">
            <div className="bg-cover  bg-no-repeat  bg-hero-img w-full md:w-[600px] lg:w-[900px] 2xl:w-[1440px] 2xl:h-[800px]  rounded-xl p-2 lg:p-10 sm:shadow-2xl ">
              <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-2/3 2xl:h-[700px] 2xl:mt-5 p-4 2xl:p-6">
                <div className="flex flex-col w-full">
                  <div className="flex flex-col justify-center lg:px-4 pt-8 my-auto md:justify-start md:pt-0  2xl:px-10">
                   <div className='py-12 pt-3'>
                   <div className="2xl:mb-3">
                   {/* { view !== null &&
                    <header className='flex justify-end'>
                        <button 
                          type='button' 
                          className='bi bi-arrow-left text-md rounded-lg py-3 px-5 bg-slate-200'
                          onClick={ () => setView(null) }
                         > Back 
                        </button>
                     </header>} */}
                        <div className="flex md:items-center justify-start w-full  overflow-hidden">
                          <h1 className="text-3xl md:w-3/4 object-contain font-medium text-darkBlue">
                            Welcome To The {userData?.exam?.title}
                          </h1>
                        </div>
                        <p className="my-2 text-md text-slate-500">
                          Please enter your work email to begin.
                        </p>
                      </div>
                     { view === null ?
                      <>
                         <button
                        type="submit" onClick={ () => setView('join') }
                        className="w-full mt-8 px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                      >
                        <span className="w-full text-xl 2xl:text-2xl">Register Exam</span>
                      </button>
                       <p className='text-center my-3'>
                          Or
                       </p>
                       <button
                        type="button"
                        onClick={ () => setView('rejoin') }
                        className="w-full mt-0 px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-slate-700 transition duration-200 ease-in bg-slate-300 shadow-md  focus:outline-none focus:ring-2"
                      >
                        <span className="w-full text-xl 2xl:text-2xl">Rejoin Exam</span>
                  </button>
                      </>
                     : null }
                   </div>
                    {  view === 'join' ? 
                    <form
                      className="flex flex-col md:-mt-10 pt-3 md:pt-0"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <div className="2xl:mb-10">
                        <div className="flex md:items-center justify-start w-full  overflow-hidden">
                          <h1 className="text-3xl md:w-3/4 object-contain font-medium text-darkBlue">
                            Good {greetTime()}
                          </h1>
                        </div>
                        <p className="my-2 text-baseFont text-slate-500">
                          Please sign into your account
                        </p>
                      </div>
                      <p className='mt-3 text-md py-2 px-2 text-slate-600 bg-slate-50'> 
                         Your Rejoin Code is: {userData?.resumption_code || 'Nil'}
                         <button 
                           type='button' className='bi text-primary bi-clipboard ml-2'
                           onClick={ () => {
                              navigator.clipboard.writeText(userData?.resumption_code || 'Nil')
                              toast.success('Copied to clipboard!')
                           } }
                          />
                         &nbsp;&nbsp; in case you lose connectivity during the exam.
                      </p> */}
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-5 pt-5 max-[500px]:-mt-16'>
                         {
                        userData?.formFields?.length ?
                        sortFields(userData)?.map((_field: any) => {
                          const {field} = _field;
                          if(field?.field_type === 'select') return  (
                            <div key={_field.id}>  
                              <div className="flex flex-col">
                                <label
                                  htmlFor={field.field_key}
                                  className="text-md font-semibold text-gray-600"
                                >
                                  {field?.field_name} <span className='text-red-500 text-xl'>*</span>
                                </label>
                                {/*!selectOptions[field.field_key] ? (
                                  <div className="animate-pulse flex-1 rounded-lg bg-gray-200 w-full py-4 2xl:py-8 px-4" />
                                ) : */ (
                                  <select
                                    id={field.field_key}
                                    className="flex-1 appearance-none rounded-lg border border-gray-300 w-full py-4 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                                    {...formik.getFieldProps(field.field_key)}
                                  >
                                    <option value="">Select {field.field_name}</option>
                                    {
                                      field?.field_key === 'subsidiary' ?
                                      subsidiaries?.map((subsidiary: any) => (
                                        <option key={subsidiary?.id} value={subsidiary?.id}>{subsidiary?.name}</option>
                                      ))
                                    : 
                                    field?.field_key === 'country' ?
                                    countries?.map((country: any) => (
                                      <option key={country?.id} value={country?.id}>{country?.name}</option>
                                    )) :
                                    field?.field_key === 'grade' ?
                                    grades?.map((grade: any) => (
                                      <option key={grade?.id} value={grade?.id}>{grade?.name}</option>
                                    )) : 
                                    null }
                                    
                                  </select>
                                )}
                                {formik.touched[field.field_key] && formik.errors[field.field_key] && (
                                  <div className="text-red-500 text-sm mt-2">{formik.errors[field.field_key]}</div>
                                )}
                              </div>
                            </div>
                          )
                          return  <div key={_field.id}>  
                              <div className="flex flex-col">
                                <label
                                  htmlFor={field.field_key}
                                  className="text-md font-semibold text-gray-600"
                                >
                                  {field?.field_name} <span className='text-red-500 text-xl'>*</span>
                                </label>
                                <div className="flex relative">
                                  {field?.field_key === 'password' ? (
                                    <div className="relative flex  w-full focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent">
                                      <input
                                        type={showPassword ? "text" : "password"}
                                        id={field.field_key}
                                        disabled={field?.field_key === 'email'}
                                        className=" flex-1 appearance-none  border-l border-t border-b rounded-e-none rounded-xl border-gray-300 w-full py-4 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none "
                                        //placeholder={`Enter your ${field?.field_name}`}
                                        {...formik.getFieldProps(field.field_key)}
                                      />
                                      <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="inline-flex rounded-r-lg  appearance-none  items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm cursor-pointer focus:outline-none "
                                      >
                                        {showPassword ? (
                                          <AiOutlineEye />
                                        ) : (
                                          <AiOutlineEyeInvisible />
                                        )}
                                      </button>
                                    </div>
                                  ) : (
                                    <input
                                      type={field?.field_type}
                                      id={field.field_key}
                                      className=" flex-1 appearance-none rounded-lg border border-gray-300 w-full py-4 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
                                      placeholder={`Enter Your ${field.field_name}`}
                                      {...formik.getFieldProps(field.field_key)}
                                    />
                                  )}
                                </div>
                                {formik.touched[field.field_key] && formik.errors[field.field_key] ? (
                                  <div className="text-red-500 text-sm mt-2">{formik.errors[field.field_key]}</div>
                                ) : null}
                              </div>
                            </div>
                        })
                      : 
                       <p className='text-center py-8'> There no any form fields for this exam. </p>
                      }
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full mt-8 px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                      >
                        {isSubmitting ? "Submitting..." : <span className="w-full text-xl 2xl:text-2xl">Register</span>}
                      </button>
                       {/* <p className='text-center my-3'>
                          Or
                       </p>
                       <button
                        type="button"
                        disabled={isSubmitting}
                        onClick={ () => setView('rejoin') }
                        className="w-full mt-0 px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-slate-700 transition duration-200 ease-in bg-slate-300 shadow-md  focus:outline-none focus:ring-2"
                      >
                        <span className="w-full text-xl 2xl:text-2xl">Rejoin Lost Test</span>
                      </button> */}
                    </form> : 
                    view === 'rejoin' ?
                    <div className='py-5'>
                     <fieldset className="border border-gray-200 -mt-5 p-4 rounded-xl shadow">
                      <legend className="px-4 text-lg font-semibold text-gray-700 bg-white rounded-md border border-gray-300">
                        Work Email 
                      </legend>
                      <div className="relative flex  w-full focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent">
                            <input
                              type={"email"}
                              value={defaultEmail}
                              onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                                 setDefaultEmail(event.target.value as string)
                              } }
                              className=" flex-1 appearance-none  border rounded-xl border-gray-300 w-full py-4 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none "
                              placeholder="Enter your work email address"
                            />
                          </div>
                          <div className='flex justify-end mt-4'>
                          <button
                              type="button"
                              //disabled={isSubmitting}
                              onClick={rejoinExam}
                              className=" mt-3 px-12 2xl:py-4 py-2 rounded-lg text-sm text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                              >
                              {
                                isSubmittingCheckEmail ? 'Submitting...' : <span className="w-full text-lg">Start Exam</span>
                              }
                              </button>
                          </div>
                    </fieldset>
                    </div>
                    : null } 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default JointExam;
