import { exams } from '../../../api/exams';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import SectionRange from './components/SectionRange';
import { v4 } from "uuid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { convertTo12Hour } from '../../../utils/dateTimeFormatter';
import EmptyData from '../../../components/EmptyData';
import Loader from '../../../components/loader/Loader';
import Result, { statusColor } from './components/Result';
import { useDispatch } from 'react-redux';
import { clearFlaggedQuestion } from '../../../features/candidate-exam/candidate-exam-slice';
import toast from 'react-hot-toast';

const Results = () => {
  
  const {id} = useParams()
  const user = JSON.parse(sessionStorage.getItem('user'))
  const { data: results, isLoading } = useQuery(
    ["exam results"],
    async () => {
      const data = await exams.getExamResults({
          userId:  user?.user?.user_id  || sessionStorage.getItem('user_id'),
          examId:  id
      });
      return data;
    },
    {
      select: (response) => response,
    },
  );

  const results_ = results?.data || []
  
     const {isLoading: retakeLoading, mutateAsync} = useMutation(exams.retakeExam)
    const dispatch = useDispatch()
    const navigate = useNavigate();
  
    const retakeExam = async (examId) => {
      try {
        const response = await mutateAsync({examId, userId: sessionStorage.getItem('user_id')});
         if(response?.data?.redirect_to_results){
            return toast.error('You have reach the maximum attemp limit')
          }       
        dispatch(clearFlaggedQuestion(null));
                 sessionStorage.setItem("user_id", response?.data?.user?.user_id )
                 window.sessionStorage.setItem("active-assessment-id", response?.data?.user?.exam_id);
                 window.sessionStorage.setItem("active-assessment-user-id", response?.data?.user?.user_exam_id);
                 window.sessionStorage.setItem("active-assessment", JSON.stringify(response?.data?.exam));
                 window.sessionStorage.setItem("time_left", response?.data?.exam?.time_left);
                 window.sessionStorage.setItem("duration", response?.data?.exam?.duration);
                 window.sessionStorage.setItem("ql", response?.data?.exam?.number_of_questions);
                 window.sessionStorage.setItem("starting-index", response?.data?.exam?.number_of_questions_answered);
                // navigate("/candidate/assessments/instructions");
                if(response?.data?.exam?.number_of_attempts) {
                  sessionStorage.setItem('attempt_count', response?.data?.exam?.number_of_attempts)
                }  
                navigate(`/candidate/assessments/instructions`)
      } catch (error) {
        toast.error(error?.response?.data?.message)
      }
    }
  

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <header className='bg-white sticky top-0 flex justify-between items-center py-5 px-4 rounded-md mb-4'>
        {sessionStorage.getItem('activeExam') }
        {
          parseInt(sessionStorage.getItem('attempt_count')) <= 3 ?
              <button  className="text-white bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={ () => retakeExam(id) }
                >
                   {retakeLoading ? 'Loading...' : 'Retake Exam'} 
             </button>
          : null
        }
      </header>
      <header className='bg-white flex justify-between items-center py-5 px-4 rounded-md mb-4'>
        <h1 className='text-lg'>
          Overall Results Status:
          <span
            className={`capitalize ml-2 text-${
              results?.overall_result_status && statusColor[results?.overall_result_status]}`
              }
            >
              {results?.overall_result_status}
            </span>
        </h1>
      </header>
      {/*USER DETAILS*/}
      {/* <UserDetails /> */}
      {/*RESULTS*/}
      {
        results_?.length ?
          results_.map((results, index) => {
            return(
            <section className='bg-white mb-5 p-3 rounded-md' key={v4()}>
                 <Result 
                   key={v4()} results={results} isLoading={isLoading} 
                    index={index}
                   />
            </section>
    )}) 
        : null
      }
    </React.Fragment>
  );
};

export default Results;
