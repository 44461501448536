import { createApiClient } from "./client";
import { ExamUser } from "./types/jointExam.types";

const client = createApiClient();

export const api = {
  getExam: ({ id }: {id: string}) => client.get(`/exams/${id}`).then(({ data }) => data),
  loginToExam: (payload : ExamUser) => client
  .post(`/auth/register/exam`, payload).then(({ data }) => data),
  checkExam: (payload : any) => client
  .post(`/auth/register/exam/confirm-email`, payload).then(({ data }) => data),
  
  getSelections: ({path}: {path: string}) => client.get(`${path}`).then(({ data }) => data),
};
