import axios from "axios";
import baseUrl from "../url";

export const createApiClient = () => {
    const client = axios.create({
        baseURL: baseUrl,
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      
      client.interceptors.request.use(
        async (config) => {
          const token = sessionStorage.getItem("userToken");
      
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
      
      client.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (
            error.response &&
            error.response.status === 401 &&
            (error.config.url !== 'auth/login')
          ) {
            console.log(error.config.url);
            
            // Clear session storage token and user
            sessionStorage.removeItem("userToken");
            sessionStorage.removeItem("user");
            // Redirect to login page
            //window.location.replace("/");
          }
      
          return Promise.reject(error);
        }
      );
    return client;      
}